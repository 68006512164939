import {
  denormalisedResponseEntities,
  getCurrentUserReactions,
  isWindowDefined,
} from '../util/data';
import { storableError } from '../util/errors';
import { LISTING_STATE_PUBLISHED } from '../util/types';
import * as log from '../util/log';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { listingsIncludeParams } from '../containers/ListingPage/ListingPage.duck';
import { integrationAPI, intercomAPI } from '../util/api';
import { util as sdkUtil } from '../util/sdkLoader';
import { addMarketplaceEntities } from './marketplaceData.duck';
import { notification } from '../util/notification';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_PROVIDER_NOTIFICATIONS_REQUEST = 'app/user/FETCH_PROVIDER_NOTIFICATIONS_REQUEST';
export const FETCH_PROVIDER_NOTIFICATIONS_SUCCESS = 'app/user/FETCH_PROVIDER_NOTIFICATIONS_SUCCESS';
export const FETCH_PROVIDER_NOTIFICATIONS_ERROR = 'app/user/FETCH_PROVIDER_NOTIFICATIONS_ERROR';

export const FETCH_CUSTOMER_NOTIFICATIONS_REQUEST = 'app/user/FETCH_CUSTOMER_NOTIFICATIONS_REQUEST';
export const FETCH_CUSTOMER_NOTIFICATIONS_SUCCESS = 'app/user/FETCH_CUSTOMER_NOTIFICATIONS_SUCCESS';
export const FETCH_CUSTOMER_NOTIFICATIONS_ERROR = 'app/user/FETCH_CUSTOMER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const CURRENT_USER_ONLINE_REQUEST = 'app/user/CURRENT_USER_ONLINE_REQUEST';
export const CURRENT_USER_ONLINE_SUCCESS = 'app/user/CURRENT_USER_ONLINE_SUCCESS';
export const CURRENT_USER_ONLINE_ERROR = 'app/user/CURRENT_USER_ONLINE_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

// Action types for following the user which is currently being
// used in containers/ListingPage.js and containers/ExperiencePage.js.
export const FOLLOW_USER_REQUEST = 'app/user/FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'app/user/FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_ERROR = 'app/user/FOLLOW_USER_ERROR';

export const UNFOLLOW_USER_REQUEST = 'app/user/UNFOLLOW_USER_REQUEST';
export const UNFOLLOW_USER_SUCCESS = 'app/user/UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_ERROR = 'app/user/UNFOLLOW_USER_ERROR';

export const ADD_LISTING_TO_WATCHLIST_REQUEST = 'app/user/ADD_LISTING_TO_WATCHLIST_REQUEST';
export const ADD_LISTING_TO_WATCHLIST_SUCCESS = 'app/user/ADD_LISTING_TO_WATCHLIST_SUCCESS';
export const ADD_LISTING_TO_WATCHLIST_ERROR = 'app/user/ADD_LISTING_TO_WATCHLIST_ERROR';

export const REMOVE_LISTING_FROM_WATCHLIST_REQUEST =
  'app/user/REMOVE_LISTING_FROM_WATCHLIST_REQUEST';
export const REMOVE_LISTING_FROM_WATCHLIST_SUCCESS =
  'app/user/REMOVE_LISTING_FROM_WATCHLIST_SUCCESS';
export const REMOVE_LISTING_FROM_WATCHLIST_ERROR = 'app/user/REMOVE_LISTING_FROM_WATCHLIST_ERROR';

export const SAVE_RECENTLY_VIEWED_LISTING_REQUEST = 'app/user/SAVE_RECENTLY_VIEWED_LISTING_REQUEST';
export const SAVE_RECENTLY_VIEWED_LISTING_SUCCESS = 'app/user/SAVE_RECENTLY_VIEWED_LISTING_SUCCESS';
export const SAVE_RECENTLY_VIEWED_LISTING_ERROR = 'app/user/SAVE_RECENTLY_VIEWED_LISTING_ERROR';

export const QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST =
  'app/user/QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST';
export const QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS =
  'app/user/QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS';
export const QUERY_RECENTLY_VIEWED_LISTINGS_ERROR =
  'app//user/QUERY_RECENTLY_VIEWED_LISTINGS_ERROR';

export const SAVE_BILLING_DETAILS_REQUEST = 'app/user/SAVE_BILLING_DETAILS_REQUEST';
export const SAVE_BILLING_DETAILS_SUCCESS = 'app/user/SAVE_BILLING_DETAILS_SUCCESS';
export const SAVE_BILLING_DETAILS_ERROR = 'app/user/SAVE_BILLING_DETAILS_ERROR';

export const REMOVE_BILLING_DETAILS_REQUEST = 'app/user/REMOVE_BILLING_DETAILS_REQUEST';
export const REMOVE_BILLING_DETAILS_SUCCESS = 'app/user/REMOVE_BILLING_DETAILS_SUCCESS';
export const REMOVE_BILLING_DETAILS_ERROR = 'app/user/REMOVE_BILLING_DETAILS_ERROR';

export const GENERATE_INTERCOM_USER_HASH_REQUEST = 'app/user/GENERATE_INTERCOM_USER_HASH_REQUEST';
export const GENERATE_INTERCOM_USER_HASH_SUCCESS = 'app/user/GENERATE_INTERCOM_USER_HASH_SUCCESS';
export const GENERATE_INTERCOM_USER_HASH_ERROR = 'app/user/GENERATE_INTERCOM_USER_HASH_ERROR';

export const FETCH_CURRENT_USER_REACTIONS_SUCCESS = 'app/user/FETCH_CURRENT_USER_REACTIONS_SUCCESS';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};

const initialState = {
  currentUser: null,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  providerNotificationCount: 0,
  providerNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,
  currentUserOnlineError: null,
  currentUserReactions: [],
  followUserInProgress: false,
  followUserError: null,
  unfollowUserInProgress: false,
  unfollowUserError: null,
  addListingToWatchlistInProgress: false,
  addListingToWatchlistError: null,
  removeListingFromWatchlistInProgress: false,
  removeListingFromWatchlistError: null,
  currentWatchlistListingId: null,
  currentWatchlistErrorListingIds: [],
  saveRecentlyViewedListingInProgress: false,
  saveRecentlyViewedListingError: null,
  recentlyViewedListingIds: [],
  queryRecentlyViewedListingsInProgress: false,
  queryRecentlyViewedListingsError: null,
  saveBillingDetailsInProgress: false,
  saveBillingDetailsError: null,
  removeBillingDetailsInProgress: false,
  removeBillingDetailsError: null,
  generateIntercomUserHashInProgress: false,
  generateIntercomUserHashError: null,
  intercomUserHash: null,
};

const resultIds = data => data.data.map(d => d.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return { ...state, currentUser: mergeCurrentUser(state.currentUser, payload) };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, currentUserShowError: payload };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        providerNotificationCount: 0,
        providerNotificationCountError: null,
        customerNotificationCount: 0,
        customerNotificationError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CUSTOMER_NOTIFICATIONS_REQUEST:
      return { ...state, customerNotificationCountError: null };
    case FETCH_CUSTOMER_NOTIFICATIONS_SUCCESS:
      return { ...state, customerNotificationCount: payload.transactions.length || 0 };
    case FETCH_CUSTOMER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, customerNotificationCountError: payload };

    case FETCH_PROVIDER_NOTIFICATIONS_REQUEST:
      return { ...state, providerNotificationCountError: null };
    case FETCH_PROVIDER_NOTIFICATIONS_SUCCESS:
      return { ...state, providerNotificationCount: payload.transactions.length || 0 };
    case FETCH_PROVIDER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, providerNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case CURRENT_USER_ONLINE_REQUEST:
      return {
        ...state,
      };
    case CURRENT_USER_ONLINE_SUCCESS:
      return {
        ...state,
      };
    case CURRENT_USER_ONLINE_ERROR:
      return {
        ...state,
        currentUserOnlineError: payload,
      };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FOLLOW_USER_REQUEST:
      return {
        ...state,
        followUserInProgress: true,
        followUserError: null,
      };
    case FOLLOW_USER_SUCCESS:
      return {
        ...state,
        followUserInProgress: false,
        followUserError: null,
      };
    case FOLLOW_USER_ERROR:
      return {
        ...state,
        followUserInProgress: false,
        followUserError: payload,
      };

    case UNFOLLOW_USER_REQUEST:
      return {
        ...state,
        unfollowUserInProgress: true,
        unfollowUserError: null,
      };
    case UNFOLLOW_USER_SUCCESS:
      return {
        ...state,
        unfollowUserInProgress: false,
        unfollowUserError: null,
      };
    case UNFOLLOW_USER_ERROR:
      return {
        ...state,
        unfollowUserInProgress: false,
        unfollowUserError: payload,
      };

    case ADD_LISTING_TO_WATCHLIST_REQUEST:
      return {
        ...state,
        currentWatchlistListingId: payload.listingId,
        addListingToWatchlistInProgress: true,
        addListingToWatchlistError: null,
      };

    case ADD_LISTING_TO_WATCHLIST_SUCCESS:
      return {
        ...state,
        currentWatchlistListingId: null,
        addListingToWatchlistInProgress: false,
        addListingToWatchlistError: null,
      };
    case ADD_LISTING_TO_WATCHLIST_ERROR:
      return {
        ...state,
        currentWatchlistListingId: null,
        currentWatchlistErrorListingIds: [
          ...state.currentWatchlistErrorListingIds,
          payload.listingId,
        ],
        addListingToWatchlistInProgress: false,
        addListingToWatchlistError: payload.error,
      };

    case REMOVE_LISTING_FROM_WATCHLIST_REQUEST:
      return {
        ...state,
        currentWatchlistListingId: payload.listingId,
        removeListingFromWatchlistInProgress: true,
        removeListingFromWatchlistError: null,
      };

    case REMOVE_LISTING_FROM_WATCHLIST_SUCCESS:
      return {
        ...state,
        currentWatchlistListingId: null,
        removeListingFromWatchlistInProgress: false,
        removeListingFromWatchlistError: null,
      };
    case REMOVE_LISTING_FROM_WATCHLIST_ERROR:
      return {
        ...state,
        currentWatchlistListingId: null,
        currentWatchlistErrorListingIds: [
          ...state.currentWatchlistErrorListingIds,
          payload.listingId,
        ],
        removeListingFromWatchlistInProgress: false,
        removeListingFromWatchlistError: payload.error,
      };

    case SAVE_RECENTLY_VIEWED_LISTING_REQUEST:
      return {
        ...state,
        saveRecentlyViewedListingInProgress: true,
        saveRecentlyViewedListingError: null,
      };
    case SAVE_RECENTLY_VIEWED_LISTING_SUCCESS:
      return {
        ...state,
        saveRecentlyViewedListingInProgress: false,
        saveRecentlyViewedListingError: null,
      };
    case SAVE_RECENTLY_VIEWED_LISTING_ERROR:
      return {
        ...state,
        saveRecentlyViewedListingInProgress: false,
        saveRecentlyViewedListingError: null,
      };

    case QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST:
      return {
        ...state,
        queryRecentlyViewedListingsInProgress: true,
        queryRecentlyViewedListingsError: null,
        recentlyViewedListingIds: [],
      };
    case QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS:
      return {
        ...state,
        queryRecentlyViewedListingsInProgress: false,
        queryRecentlyViewedListingsError: null,
        recentlyViewedListingIds: resultIds(payload.data),
      };
    case QUERY_RECENTLY_VIEWED_LISTINGS_ERROR:
      return {
        ...state,
        queryRecentlyViewedListingsInProgress: false,
        queryRecentlyViewedListingsError: payload,
      };

    case SAVE_BILLING_DETAILS_REQUEST:
      return {
        ...state,
        saveBillingDetailsInProgress: true,
        saveBillingDetailsError: null,
      };
    case SAVE_BILLING_DETAILS_SUCCESS:
      return {
        ...state,
        saveBillingDetailsInProgress: false,
        saveBillingDetailsError: null,
      };
    case SAVE_BILLING_DETAILS_ERROR:
      return {
        ...state,
        saveBillingDetailsInProgress: false,
        saveBillingDetailsError: payload,
      };

    case REMOVE_BILLING_DETAILS_REQUEST:
      return {
        ...state,
        removeBillingDetailsInProgress: true,
        removeBillingDetailsError: null,
      };
    case REMOVE_BILLING_DETAILS_SUCCESS:
      return {
        ...state,
        removeBillingDetailsInProgress: false,
        removeBillingDetailsError: null,
      };
    case REMOVE_BILLING_DETAILS_ERROR:
      return {
        ...state,
        removeBillingDetailsInProgress: false,
        removeBillingDetailsError: payload,
      };

    case GENERATE_INTERCOM_USER_HASH_REQUEST:
      return {
        ...state,
        generateIntercomUserHashInProgress: true,
        generateIntercomUserHashError: null,
        intercomUserHash: null,
      };
    case GENERATE_INTERCOM_USER_HASH_SUCCESS:
      return {
        ...state,
        generateIntercomUserHashInProgress: false,
        generateIntercomUserHashError: null,
        intercomUserHash: payload.hash,
      };
    case GENERATE_INTERCOM_USER_HASH_ERROR:
      return {
        ...state,
        generateIntercomUserHashInProgress: false,
        generateIntercomUserHashError: payload,
        intercomUserHash: null,
      };

    case FETCH_CURRENT_USER_REACTIONS_SUCCESS:
      return {
        ...state,
        currentUserReactions: payload.currentUserReactions,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.providerNotificationCountError ||
    user.customerNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchProviderNotificationsRequest = () => ({
  type: FETCH_PROVIDER_NOTIFICATIONS_REQUEST,
});

export const fetchProviderNotificationsSuccess = transactions => ({
  type: FETCH_PROVIDER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchProviderNotificationsError = e => ({
  type: FETCH_PROVIDER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCustomerNotificationsRequest = () => ({
  type: FETCH_CUSTOMER_NOTIFICATIONS_REQUEST,
});

export const fetchCustomerNotificationsSuccess = transactions => ({
  type: FETCH_CUSTOMER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

const fetchCustomerNotificationsError = e => ({
  type: FETCH_CUSTOMER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const currentUserOnlineRequest = () => ({
  type: CURRENT_USER_ONLINE_REQUEST,
});
export const currentUserOnlineSuccess = () => ({
  type: CURRENT_USER_ONLINE_SUCCESS,
});
export const currentUserOnlineError = () => ({
  type: CURRENT_USER_ONLINE_ERROR,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const followUserRequest = () => ({
  type: FOLLOW_USER_REQUEST,
});
export const followUserSuccess = () => ({
  type: FOLLOW_USER_SUCCESS,
});
export const followUserError = e => ({
  type: FOLLOW_USER_ERROR,
  error: true,
  payload: e,
});

export const unfollowUserRequest = () => ({
  type: UNFOLLOW_USER_REQUEST,
});
export const unfollowUserSuccess = () => ({
  type: UNFOLLOW_USER_SUCCESS,
});
export const unfollowUserError = e => ({
  type: UNFOLLOW_USER_ERROR,
  error: true,
  payload: e,
});

export const addListingToWatchlistRequest = listingId => ({
  type: ADD_LISTING_TO_WATCHLIST_REQUEST,
  payload: { listingId },
});
export const addListingToWatchlistSuccess = () => ({
  type: ADD_LISTING_TO_WATCHLIST_SUCCESS,
});
export const addListingToWatchlistError = (e, listingId) => ({
  type: ADD_LISTING_TO_WATCHLIST_ERROR,
  payload: { error: e, listingId },
});

export const removeListingFromWatchlistRequest = listingId => ({
  type: REMOVE_LISTING_FROM_WATCHLIST_REQUEST,
  payload: { listingId },
});
export const removeListingFromWatchlistSuccess = () => ({
  type: REMOVE_LISTING_FROM_WATCHLIST_SUCCESS,
});
export const removeListingFromWatchlistError = (e, listingId) => ({
  type: REMOVE_LISTING_FROM_WATCHLIST_ERROR,
  payload: { error: e, listingId },
});

export const saveRecentlyViewedListingRequest = () => ({
  type: SAVE_RECENTLY_VIEWED_LISTING_REQUEST,
});
export const saveRecentlyViewedListingSuccess = () => ({
  type: SAVE_RECENTLY_VIEWED_LISTING_SUCCESS,
});
export const saveRecentlyViewedListingError = e => ({
  type: SAVE_RECENTLY_VIEWED_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryRecentlyViewedListingsRequest = () => ({
  type: QUERY_RECENTLY_VIEWED_LISTINGS_REQUEST,
});
export const queryRecentlyViewedListingsSuccess = response => ({
  type: QUERY_RECENTLY_VIEWED_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const queryRecentlyViewedListingsError = e => ({
  type: QUERY_RECENTLY_VIEWED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const saveBillingDetailsRequest = () => ({
  type: SAVE_BILLING_DETAILS_REQUEST,
});
export const saveBillingDetailsSuccess = () => ({
  type: SAVE_BILLING_DETAILS_SUCCESS,
});
export const saveBillingDetailsError = e => ({
  type: SAVE_BILLING_DETAILS_ERROR,
  error: true,
  payload: e,
});

export const removeBillingDetailsRequest = () => ({
  type: REMOVE_BILLING_DETAILS_REQUEST,
});
export const removeBillingDetailsSuccess = () => ({
  type: REMOVE_BILLING_DETAILS_SUCCESS,
});
export const removeBillingDetailsError = e => ({
  type: REMOVE_BILLING_DETAILS_ERROR,
  error: true,
  payload: e,
});

export const generateIntercomUserHashRequest = () => ({
  type: GENERATE_INTERCOM_USER_HASH_REQUEST,
});
export const generateIntercomUserHashSuccess = response => ({
  type: GENERATE_INTERCOM_USER_HASH_SUCCESS,
  payload: { hash: response.hash },
});
export const generateIntercomUserHashError = e => ({
  type: GENERATE_INTERCOM_USER_HASH_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserReactionsSuccess = currentUserReactions => ({
  type: FETCH_CURRENT_USER_REACTIONS_SUCCESS,
  payload: { currentUserReactions },
});

// ================ Thunks ================ //

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  return sdk.ownListings
    .query()
    .then(response => {
      const listings = response.data.data;
      const hasListings = listings && listings.length > 0;
      const lastListing = hasListings ? listings[0] : null;

      const hasPublishedListings =
        hasListings && !!listings.find(l => l.attributes.state === LISTING_STATE_PUBLISHED);

      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, lastListing));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCustomerNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchCustomerNotificationsRequest());

  const apiQueryParams = {
    only: 'order',
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;

      // Map through the transactions and
      // find if user has new notifications
      const filteredTransactions = transactions.filter(
        tx => tx?.attributes?.metadata?.notifications?.customerNotification
      );

      dispatch(fetchCustomerNotificationsSuccess(filteredTransactions));
    })
    .catch(e => dispatch(fetchCustomerNotificationsError(storableError(e))));
};

export const fetchProviderNotifications = () => (dispatch, getState, sdk) => {
  dispatch(fetchProviderNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;

      // Filter the transactions directly for notifications
      const filteredTransactions = transactions.filter(
        tx => tx?.attributes?.metadata?.notifications?.providerNotification
      );

      // Dispatch the filtered transactions
      dispatch(fetchProviderNotificationsSuccess(filteredTransactions));
    })
    .catch(e => dispatch(fetchProviderNotificationsError(storableError(e))));
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.square-xsmall',
      'variants.square-xsmall2x',
    ],
    'imageVariant.square-xsmall': sdkUtil.objectQueryString({
      w: 40,
      h: 40,
      fit: 'crop',
    }),
    'imageVariant.square-xsmall2x': sdkUtil.objectQueryString({
      w: 80,
      h: 80,
      fit: 'crop',
    }),
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      const currentUserReactions = getCurrentUserReactions(currentUser);

      if (currentUserReactions?.length > 0) {
        dispatch(fetchCurrentUserReactionsSuccess(currentUserReactions));
      }

      // set current user id to the logger
      log.setUserId(currentUser.id.uuid);
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchProviderNotifications());
      dispatch(fetchCustomerNotifications());

      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }

      // Make sure auth info is up to date
      dispatch(authInfo());
      return currentUser;
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};

// We need to call integrationAPI and update the profile of the
// user that is currently followed (author of the listing).
//
// We need to maintain ids of the user that is following him.
const updateFollowerUserIds = (followingId, followerId) => (dispatch, getState, sdk) => {
  return sdk.users.show({ id: followingId }).then(response => {
    const user = response.data.data;
    const followerIds = user.attributes.profile.publicData.followerIds;
    const followerIdsArray = followerIds ? [followerId, ...followerIds] : [followerId];

    return integrationAPI.users.updateProfile({
      id: followingId,
      publicData: {
        followerIds: followerIdsArray,
      },
    });
  });
};

const updateUnfollowerUserIds = (followingId, followerId) => (dispatch, getState, sdk) => {
  return sdk.users.show({ id: followingId }).then(response => {
    const user = response.data.data;
    const followerIds = user.attributes.profile.publicData.followerIds;
    const followerIdsArray = followerIds?.filter(id => id !== followerId);

    return integrationAPI.users.updateProfile({
      id: followingId,
      publicData: {
        followerIds: followerIdsArray,
      },
    });
  });
};

export const followUser = followingId => (dispatch, getState, sdk) => {
  dispatch(followUserRequest());

  // Get currentUser from the redux store
  const { currentUser } = getState().user;

  // Array of userIds that currentUser is following
  const followingIdsFromPublicData = currentUser.attributes.profile.publicData.followingIds;

  // Form new array of following userIds
  const followingIds = followingIdsFromPublicData
    ? [followingId, ...followingIdsFromPublicData]
    : [followingId];

  return sdk.currentUser
    .updateProfile({
      publicData: {
        followingIds,
      },
    })
    .then(response => {
      dispatch(updateFollowerUserIds(followingId, currentUser.id.uuid));
      dispatch(followUserSuccess());
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => dispatch(followUserError(storableError(e))));
};

export const unfollowUser = followingId => (dispatch, getState, sdk) => {
  dispatch(followUserRequest());

  // Get currentUser from the redux store
  const { currentUser } = getState().user;

  // Array of userIds that currentUser is following
  const followingIdsFromPublicData = currentUser.attributes.profile.publicData.followingIds;

  // Form new array of following userIds
  const followingIds = followingIdsFromPublicData?.filter(id => id !== followingId);

  return sdk.currentUser
    .updateProfile({
      publicData: {
        followingIds,
      },
    })
    .then(response => {
      dispatch(updateUnfollowerUserIds(followingId, currentUser.id.uuid));
      dispatch(followUserSuccess());
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => dispatch(followUserError(storableError(e))));
};

export const addListingToWatchlist = listingId => (dispatch, getState, sdk) => {
  dispatch(addListingToWatchlistRequest(listingId));

  // Get currentUser from the redux store
  const { currentUser } = getState().user;

  // Array of currentUser watchlistIds
  const watchlistIdsFromPublicData = currentUser.attributes.profile.publicData.watchlistIds;

  // Form new array of watchlistIds
  const watchlistIds = watchlistIdsFromPublicData
    ? [listingId, ...watchlistIdsFromPublicData]
    : [listingId];

  return sdk.currentUser
    .updateProfile({
      publicData: {
        watchlistIds,
      },
    })
    .then(response => {
      dispatch(addListingToWatchlistSuccess());
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => {
      notification.error(
        'Error adding listing to watchlist. Please refresh the page and try again.'
      );

      dispatch(addListingToWatchlistError(storableError(e), listingId));
    });
};

export const removeListingFromWatchlist = listingId => (dispatch, getState, sdk) => {
  dispatch(removeListingFromWatchlistRequest(listingId));

  // Get currentUser from the redux store
  const { currentUser } = getState().user;

  // Array of currentUser watchlistIds
  const watchlistIdsFromPublicData = currentUser.attributes.profile.publicData.watchlistIds || [];

  // Form new array of watchlistIds
  const watchlistIds = watchlistIdsFromPublicData.filter(id => id !== listingId);

  return sdk.currentUser
    .updateProfile({
      publicData: {
        watchlistIds,
      },
    })
    .then(response => {
      dispatch(removeListingFromWatchlistSuccess());
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => {
      notification.error(
        'Error removing listing from watchlist. Please refresh the page and try again.'
      );
      dispatch(removeListingFromWatchlistError(storableError(e), listingId));
    });
};

export const currentUserOnline = () => (dispatch, getState, sdk) => {
  dispatch(currentUserOnlineRequest());

  const lastSeen = new Date().valueOf();

  return sdk.currentUser
    .updateProfile({
      publicData: {
        lastSeen,
      },
    })
    .then(response => {
      dispatch(currentUserOnlineSuccess(response));
    })
    .catch(e => dispatch(currentUserOnlineError(storableError(e))));
};

export const saveRecentlyViewedListingId = listingId => (dispatch, getState, sdk) => {
  dispatch(saveRecentlyViewedListingRequest());

  const { currentUser } = getState().user;

  if (!currentUser) {
    // Save to local storage for guest users
    const recentlyViewedListingIdsFromLocalStorage =
      (isWindowDefined() && JSON.parse(localStorage.getItem('recentlyViewedListingIds'))) || [];
    if (!recentlyViewedListingIdsFromLocalStorage.includes(listingId)) {
      const updatedRecentlyViewedListingIds = [
        ...recentlyViewedListingIdsFromLocalStorage.slice(0, 9),
        listingId,
      ];
      if (isWindowDefined()) {
        localStorage.setItem(
          'recentlyViewedListingIds',
          JSON.stringify(updatedRecentlyViewedListingIds)
        );
      }
    }
    dispatch(saveRecentlyViewedListingSuccess());
    return Promise.resolve(recentlyViewedListingIdsFromLocalStorage);
  }

  const recentlyViewedListingIdsFromProtectedData =
    currentUser?.attributes?.profile?.protectedData?.recentlyViewedListingIds || [];

  if (recentlyViewedListingIdsFromProtectedData.includes(listingId)) {
    dispatch(saveRecentlyViewedListingSuccess());
    return Promise.resolve(currentUser);
  }

  const updatedRecentlyViewedListingIds = recentlyViewedListingIdsFromProtectedData.slice(0, 9);
  const recentlyViewedListingIds = [...updatedRecentlyViewedListingIds, listingId];

  return sdk.currentUser
    .updateProfile(
      {
        protectedData: {
          recentlyViewedListingIds,
        },
      },
      { expand: true }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveRecentlyViewedListingSuccess());
      return currentUser;
    })
    .catch(e => dispatch(saveRecentlyViewedListingError(storableError(e))));
};

export const queryRecentlyViewedListings = () => async (dispatch, getState, sdk) => {
  dispatch(queryRecentlyViewedListingsRequest());

  const { currentUser } = getState().user;

  let recentlyViewedListingIds = [];

  if (!currentUser) {
    // Retrieve recently viewed listing IDs from local storage for guest users
    recentlyViewedListingIds =
      (isWindowDefined() && JSON.parse(localStorage.getItem('recentlyViewedListingIds'))) || [];
  } else {
    // Retrieve recently viewed listing IDs from currentUser if available
    recentlyViewedListingIds =
      currentUser?.attributes?.profile?.protectedData?.recentlyViewedListingIds || [];
  }

  return sdk.listings
    .query({
      ids: recentlyViewedListingIds,
      ...listingsIncludeParams,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(queryRecentlyViewedListingsSuccess(response));

      return response;
    })
    .catch(e => dispatch(queryRecentlyViewedListingsError(storableError(e))));
};

export const saveBillingDetails = billingDetails => (dispatch, getState, sdk) => {
  dispatch(saveBillingDetailsRequest());

  return sdk.currentUser
    .updateProfile({
      protectedData: {
        billingDetails,
      },
    })
    .then(response => {
      dispatch(saveBillingDetailsSuccess());
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => dispatch(saveBillingDetailsError(storableError(e))));
};

export const removeBillingDetails = () => (dispatch, getState, sdk) => {
  dispatch(removeBillingDetailsRequest());

  return sdk.currentUser
    .updateProfile({
      protectedData: {
        billingDetails: null,
      },
    })
    .then(response => {
      dispatch(removeBillingDetailsSuccess());
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => dispatch(removeBillingDetailsError(storableError(e))));
};

export const generateIntercomUserHash = userId => (dispatch, getState, sdk) => {
  dispatch(generateIntercomUserHashRequest());

  return intercomAPI.verification
    .generateHash({ userId })
    .then(response => {
      dispatch(generateIntercomUserHashSuccess(response.data.hash));
      return response.data.hash;
    })
    .catch(e => dispatch(generateIntercomUserHashError(storableError(e))));
};
