import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './PaymentBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, unitType, intl } = props;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="PaymentBreakdown.basePrice" />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  membership: propTypes.membership,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
