/**
 * This component will show the experience info and calculated total price.
 */
import React from 'react';
import { oneOf, string, object } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { LINE_ITEM_TAX, propTypes } from '../../util/types';
import Decimal from 'decimal.js';
import classNames from 'classnames';

// Components that include general details NOT connected to line items
import ExperienceDetails from './ExperienceDetails';
import MembershipDetails from './MembershipDetails';

// Components that include details FROM transaction line items
import LineItemBookingDetails from './LineItemBookingDetails';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemTaxMaybe from './LineItemTaxMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';

import css from './PaymentBreakdown.module.css';

export const PaymentBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    listing,
    transaction,
    paymentType,
    membership,
    timeslot,
    taxPrice,
    intl,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const lineItems = transaction.attributes.lineItems;

  // Check if tax line item already exists in transaction lineItems
  const existingTaxItem = lineItems.find(item => item.code === LINE_ITEM_TAX);
  // If a tax line item exists, use that. Otherwise, use the taxPrice from props (if available)
  const finalTaxItem =
    existingTaxItem ||
    (taxPrice && {
      code: LINE_ITEM_TAX,
      unitPrice: taxPrice,
      lineTotal: taxPrice,
      quantity: new Decimal(1),
      includeFor: ['customer'],
      reversal: false,
    });

  // Create a new array of line items with the final tax item included (if applicable)
  const updatedLineItems = finalTaxItem
    ? [...lineItems, ...(existingTaxItem ? [] : [finalTaxItem])]
    : lineItems;

  const bookingDates = timeslot?.bookingDates;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <ExperienceDetails listing={listing} timeslot={timeslot} />

      <LineItemBookingDetails timeslot={timeslot} bookingDates={bookingDates} />

      <MembershipDetails intl={intl} paymentType={paymentType} membership={membership} />

      <div className={css.details}>
        <h4 className={css.detailsTitle}>
          <FormattedMessage id="PaymentBreakdown.paymentDetails" />
        </h4>

        <LineItemBasePriceMaybe
          lineItems={updatedLineItems}
          unitType={unitType}
          membership={membership}
          intl={intl}
        />

        <LineItemTaxMaybe lineItems={updatedLineItems} isCustomer={isCustomer} intl={intl} />

        <LineItemCustomerCommissionMaybe
          lineItems={updatedLineItems}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemCustomerCommissionRefundMaybe
          lineItems={updatedLineItems}
          isCustomer={isCustomer}
          intl={intl}
        />

        <LineItemProviderCommissionMaybe
          lineItems={updatedLineItems}
          isProvider={isProvider}
          intl={intl}
        />

        <LineItemProviderCommissionRefundMaybe
          lineItems={updatedLineItems}
          isProvider={isProvider}
          intl={intl}
        />

        <LineItemTotalPrice
          transaction={transaction}
          isProvider={isProvider}
          taxPrice={taxPrice}
          intl={intl}
        />
      </div>
    </div>
  );
};

PaymentBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

PaymentBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  listing: propTypes.listing.isRequired,
  transaction: propTypes.transaction.isRequired,
  paymentType: string.isRequired,
  membership: propTypes.membership,
  timeslot: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PaymentBreakdown = injectIntl(PaymentBreakdownComponent);

PaymentBreakdown.displayName = 'PaymentBreakdown';

export default PaymentBreakdown;
